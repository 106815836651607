
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-pencil-banner {
    &__container {
        display: grid;
        grid-template-rows: 1fr;
        width: 100vw;
    }

    &__transition {
        &-enter-active {
            overflow: hidden;
            transition: grid-template-rows 0.5s ease, opacity 0.4s ease-in;
            transition-delay: 1s;
        }

        &-enter-from {
            grid-template-rows: 0fr;
            opacity: 0;
        }

        &-enter-to {
            grid-template-rows: 1fr;
            opacity: 1;
        }
    }
}
