
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-content-card-buttons {
    &__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &--left {
            left: $nu-spacer-1pt5;
        }

        &--right {
            right: $nu-spacer-1pt5;
        }
    }
}
