
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-box-launcher {
        $this: &;
        position: relative;
        height: 48px;
        width: 40px;
        justify-content: center;

        @include breakpoint(medium) {
            height: auto;
            width: auto;
            justify-content: unset;
        }

        &__product-tour-lightbox-target {
            pointer-events: none;
            height: 40px;
            width: 40px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: ' ';

            @include breakpoint(medium) {
                height: 100%;
                width: 100%;
            }
        }

        &,
        &__launcher-container,
        &__icon-container,
        &__icon {
            display: inline-flex;
            align-items: center;
        }

        &__launcher-container {
            color: $nu-primary;
        }

        &__icon-container {
            color: $nu-primary;
            border-radius: 3px;
            height: 100%;
            position: relative;
            transition: all 0.2s ease;

            &--filled {
                .c-icon {
                    fill: $nu-primary !important;

                    #transparent-body {
                        fill-opacity: 1;
                    }

                    #nuuly-counter-font {
                        fill: $nu-white;
                    }
                }
            }

            @include breakpoint(medium) {
                border: 1px solid $nu-secondary;
                padding: $nu-spacer-1 $nu-spacer-1pt5;
                margin-right: $nu-spacer-1;

                #{$this}:hover &,
                &--filled {
                    @include breakpoint(medium) {
                        color: $nu-white;
                        background-color: $nu-primary;
                        border-color: $nu-primary;

                        .c-icon {
                            fill: $nu-white !important;

                            #transparent-body {
                                fill-opacity: 0;
                            }
                        }
                    }
                }
            }
        }

        &__icon {
            &--flipped {
                transform: scaleX(-1);
            }
        }

        &__label {
            display: none;

            @include breakpoint(medium) {
                margin-left: $nu-spacer-1;
                display: inline-block;
            }
        }

        .is-animating {
            #{$this}__icon {
                animation-name: boxItemAddedIcon;
                animation-duration: 600ms;
                animation-iteration-count: 1;
            }
        }

        @keyframes boxItemAddedIcon {
            0%   { transform: scale(1);   }
            27%  { transform: scale(1.3); }
            45%  { transform: scale(1.3); }
            78%  { transform: scale(0.9); }
            100% { transform: scale(1);   }
        }
    }
