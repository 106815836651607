
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-header-links {
    &__item {
        width: 100%;
        text-align: left;
        outline-offset: -4px;
        cursor: pointer;
        padding: $nu-spacer-2;
        background-color: $nu-white;
        transition: all 0.2s ease-in-out;

        border-bottom: 1px solid $nu-gray--light;

        &:hover {
            background-color: $nu-secondary-20;
            padding-left: $nu-spacer-3;
        }
    }

    &__badge {
        padding: $nu-spacer-1 $nu-spacer-1pt5;
        margin-left: $nu-spacer-1pt5;
        border-radius: $nu-spacer-0pt5;
        background-color: $placeholder-background;
    }
}
