
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-overdue-container {
        @include modal-drawer('.c-overdue-container');
        background-color: $nu-secondary;
        height: var(--vh100);
    }
