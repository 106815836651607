
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-intercom-launcher {
        @include dropshadow;

        $triangleHeight: 12px;
        $menuPadding: $nu-spacer-2;
        $transitionDuration: 400ms;

        background-color: $nu-primary;
        border-radius: 100%;
        bottom: $nu-spacer-3;
        height: 48px;
        left: $nu-spacer-3;
        position: fixed;
        width: 48px;
        z-index: map-get($zindex, intercom-launcher);
        transition: all 0.5s ease;

        &--hidden {
            opacity: 0;
        }

        &__title {
            display: block;
        }

        &__img-container {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &__hand-img {
            width: 23px;
            pointer-events: none;
        }

        &__menu-container {
            bottom: 60px;
            height: 350px;
            position: absolute;
            width: 250px;
        }

        &__menu {
            background-color: $nu-white;
            bottom: $triangleHeight;
            box-shadow: 0 3px 3.5px 0 rgba(0, 0, 0, 0.25);
            padding: $menuPadding;
            position: absolute;
        }

        &__menu-triangle {
            background-color: $nu-white;
            bottom: -$menuPadding - ($triangleHeight / 2);
            box-shadow: 3px 3px 3.5px -2px rgba(0, 0, 0, 0.25);
            content: '';
            height: $triangleHeight;
            left: -$menuPadding - ($triangleHeight / 2) + (50px / 2);
            position: relative;
            transform: rotate(45deg);
            width: $triangleHeight;
        }

        &__link {
            color: $nu-primary;
            cursor: pointer;
        }

        .slide-up {
            &-enter-active,
            &-leave-active {
                transition: all $transitionDuration ease;
            }

            &-enter-to,
            &-leave {
                max-height: 500px;
                overflow: hidden;
            }

            &-enter,
            &-leave-to {
                overflow: hidden;
                max-height: 0;
            }
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity ($transitionDuration / 2) ease;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }
