
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-content-card {
    $this: &;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $nu-white;
    cursor: pointer;

    @include breakpoint(large) {
        flex-direction: row;
    }

    &__subheading {
        @include breakpoint(large) {
            margin-left: $nu-spacer-2;
        }

        &--default {
            @include caption;

            @include breakpoint(large) {
                @include body-copy;
            }
        }
    }

    &__container {
        border-bottom: 1px solid transparent;

        @include non-touch-device {
            &:not(&--no-border) {
                #{$this}:focus &,
                #{$this}:hover & {
                    border-color: currentColor;
                }
            }
        }
    }

    &__tooltip {
        width: 40px;
        height: 40px;
        flex-shrink: 0;

        &--mobile {
            height: 32px;

            @include breakpoint(large) {
                display: none;
            }
        }

        &--desktop {
            display: none;

            @include breakpoint(large) {
                display: flex;
            }
        }
    }
}
