
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-header-auth-menu {
    height: 100%;

    &__links {
        overflow-y: auto;
        height: calc(100% - $nu-spacer-7);
    }

    &__button {
        height: $nu-spacer-7;
        width: 100%;
        outline-offset: -4px;
        background-color: $nu-white;

        &--fixed {
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            left: 0;
            border-top: 1px solid $nu-gray--light;
        }
    }
}
