
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-content-card-carousel {
    width: 100vw;
    overflow: hidden;
    position: relative;

    &__container {
        display: grid;
    }

    &__card {
        width: 100vw;
        height: 100%;
        text-align: center;
        padding: $nu-spacer-2 $nu-spacer-8;
        grid-row: 1;
        grid-column: 1;

        @include breakpoint(large) {
            padding: $nu-spacer-1pt5 $nu-spacer-8;
        }

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__transition {
        &-enter-active,
        &-leave-active {
            transition: opacity 1s ease-in-out;

            @include breakpoint(medium) {
                transition: opacity 1.5s ease-in-out;
            }
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
        }

        &-enter-to,
        &-leave-from {
            opacity: 1;
        }
    }
}
