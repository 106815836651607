
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-autocomplete-dropdown {
        position: relative;
        z-index: 1;

        &__container {
            @include dropshadow;

            position: absolute;
            width: 100%;
            background-color: $nu-white;
            min-height: 50px;
            top: -($nu-spacer-1);
        }

        &__attribution {
            width: 100%;
            height: 40px;
            text-align: center;
            background-color: $nu-gray--light;

            img {
                width: 95px;
                height: auto;
            }
        }

        &__li {
            height: 48px;
            padding: 0 $nu-spacer-2;
            cursor: pointer;

            &:hover {
                background-color: $nu-gray--light;
            }

            img {
                margin-right: $nu-spacer-1;
            }
        }

        &__main-text {
            padding-left: $nu-spacer-1;
            color: $nu-black;
        }
    }

