
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-card-form {
        margin-top: $nu-spacer-2pt5;

        &__desktop-row-2 {
            margin-top: $nu-spacer-2pt5;
        }
    }

    .c-recurly-errors {
        &__li {
            display: inline-block;
        }
    }

    // THESE STYLES ARE FOR THE INJECTED RECURLY CREDIT CARD FORM
    .recurly-hosted-field {
        &-number,
        &-month,
        &-year,
        &-cvv {
            background-color: $transparent;
            border: 0;
            position: relative;
            height: 30px;
            margin-bottom: $nu-spacer-2pt5;
            padding: 0 $nu-spacer-1pt5;
            box-sizing: border-box;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1px;
                height: 1px;
                background-image:
                    linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-gray 50%, $nu-gray 100%);
                background-position-y: 0;
                background-size: 100% 200%;
                background-repeat: no-repeat;
                transition:
                    background-position-y 0.2s ease,
                    background-image 0.2s ease;
            }
        }
    }

    .recurly-hosted-field {
        &-month,
        &-year {
            margin-bottom: $nu-spacer-8;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-2pt5;
            }
        }
    }

    .recurly-hosted-field {
        &-number,
        &-month,
        &-year,
        &-cvv {
            &.recurly-hosted-field-focus {
                &::after {
                    bottom: -2px;
                    height: 2px;
                    background-image:
                        linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-primary 50%, $nu-primary 100%);
                }
            }
        }
    }

    .recurly-hosted-field-invalid {
        border: 1px solid $nu-primary;
    }
